import React, { useState } from 'react'
import { Link } from 'gatsby'
import { blocksToText } from '../../lib/helpers'
import Image from 'gatsby-plugin-sanity-image'
import readTimeEstimate from 'read-time-estimate'

import ArrowCTA from '../../assets/svg/arrow_cta.svg'

const StoriesList = ({ pages }) => {
  const
    [currentPage, setCurrentPage] = useState(1),
    [stories, setStories] = useState(pages[0]),
    [visible, setVisible] = useState(null);

  const prevPage = () => {
    setStories(pages[currentPage - 2])
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setStories(pages[currentPage])
    setCurrentPage(currentPage + 1)
  }

  return(
    <section id="stories-list" className="grid">

      {stories.map((story, i) => {
        const {_id, title, image, description, _rawBody, slug } = story
        const string = blocksToText(_rawBody)

        return(
          <div key={_id} className="story-container">
            <div className="layer-bottom">
              <div className={visible === i ? 'image-container visible' : 'image-container'}>
                <Image {...image} width={500}/>
              </div>
            </div>
            <div className="layer-top">
              <h3>{title}</h3>
              <p>{description}</p>
              <Link
                className="action"
                to={`/stories/${slug.current}`}
                onMouseEnter={() => setVisible(i)}
                onMouseLeave={() => setVisible(null)}>
                  <p className="ttr">READING TIME: {readTimeEstimate(string, 200).humanizedDuration} <ArrowCTA/></p>
              </Link>
            </div>
          </div>
        )
      })}

      {pages.length > 1 &&
        <>
          <button
            type="button"
            aria-label="Stories page"
            className="controls prev"
            disabled={currentPage === 1}
            onClick={prevPage}
          >
            Prev
          </button>

          <span className="controls">0{currentPage} / 0{pages.length}</span>

          <button
            type="button"
            aria-label="Next Story"
            className="controls next"
            disabled={currentPage === pages.length}
            onClick={nextPage}
          >
              Next
          </button>
        </>
      }

    </section>
  )
}

export default StoriesList
