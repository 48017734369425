// export function colorTags(color) {
//   const navTags = document.querySelectorAll('nav a')
//   const socialTags = document.querySelectorAll('.socials a')

//   for (let i = 0; i < navTags.length; i++) {
//     navTags[i].classList.add(color);
//   }

//   for (let i = 0; i < socialTags.length; i++) {
//     socialTags[i].classList.add(color);
//   }
// }

export function getPages(list, size) {
  let pages = [];

  for(let i = 0; i < list.length; i += size) {
    pages.push(list.slice(i, i+size));
  }

  return pages;
}

export function blocksToText(blocks, opts = {}) {
  const defaults = {nonTextBehavior: 'remove'}
  const options = Object.assign({}, defaults, opts)

  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return options.nonTextBehavior === 'remove' ? '' : `[${block._type} block]`
      }

      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}