import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'
import { window } from 'browser-monads'
import { getPages } from '../lib/helpers'

import Layout from "../components/layout/layout"
import SEO from '../components/seo'
import StoriesHero from "../components/stories/hero"
import StoriesMeet from "../components/stories/meet"
import StoriesList from "../components/stories/list"

const StoriesPage = ({ data }) => {
  const
    { hero, _rawStories, cta } = data.stories,
    storiesList = data.list.nodes;

  const
    postsPerPage = 6,
    [pages] = useState(getPages(storiesList, postsPerPage));

  useEffect(() => {
    const
      body = document.querySelector('body'),
      footer = document.querySelector('footer'),
      containers =document.querySelectorAll(".image-container");

    body.classList.remove('black')
    footer.classList.remove('black')

    window.onscroll = (ev) => {
      // Array.prototype.forEach.call(document.querySelectorAll(".image-container"), (function(e) {
      //   if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
      //     let t1 = Math.round(1e5 * (((window.scrollY || window.pageYOffset) - window.innerHeight) / window.innerHeight * 2 + 2)) / 1e6;
      //     if(!!e.querySelector(".hero-image")) {
      //       e.querySelector(".hero-image").style.transform = `translateY(${10 * t1}%) scale(1.1)`
      //     }
      //     if(!!e.querySelector(".image-contact")) {
      //       e.querySelector(".image-contact").style.transform = `scale(${0 + (window.scrollY || window.pageYOffset) / 10300})`
      //     }
      //   }
      // }))

      containers.forEach((e) => {
        if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
          let t1 = Math.round(1e5 * (((window.scrollY || window.pageYOffset) - window.innerHeight) / window.innerHeight * 2 + 2)) / 1e6;
          if(!!e.querySelector(".hero-image")) {
            e.querySelector(".hero-image").style.transform = `translateY(${10 * t1}%) scale(1.1)`
          }
        }
      })
    }
  })

  return(
    <Layout>
      <SEO title="Stories"/>
      <StoriesHero hero={hero} _rawStories={_rawStories}/>

      {storiesList.length > 0 && (
        <StoriesList pages={pages}/>
      )}

      <StoriesMeet cta={cta}/>
    </Layout>
  )
}

export const query = graphql`
  query StoriesQuery {
    stories: sanityStories {
      hero {
        image {
          alt
          ...Image
        }
        _rawText
      }
      _rawStories
      cta {
        _rawTitle
        _rawText
        cta
      }
    }
    list: allSanityStory(sort: {fields: _createdAt, order: DESC}) {
      nodes {
        _id
        title
        slug {
          current
        }
        image {
          alt
          ...Image
        }
        description
        _rawBody
      }
    }
  }
`

export default StoriesPage
