import React from 'react'
import { Link } from 'gatsby'

import BlockContent from '../block-content'

import BasaltMeet from '../../assets/svg/stories_meet.svg'
import ArrowCTA from '../../assets/svg/arrow_cta.svg'

const StoriesMeet = ({ cta }) => {
  return(
    <section id="stories-meet">
        <div className="layer-top" data-sal="slide-up" data-sal-delay="100" data-sal-duration="850">
          <BlockContent className="block-bold extra" blocks={cta._rawTitle || []}/>
          <BlockContent className="block-bold small" blocks={cta._rawText || []}/>
          <Link className="cta action" to="/contact">{cta.cta} <ArrowCTA/></Link>
        </div>
        <div className="layer-bottom" data-sal="fade" data-sal-delay="300" data-sal-duration="650">
          <BasaltMeet className="basalt"/>
        </div>
      </section>
  )
}

export default StoriesMeet
