import React from 'react'
import Image from 'gatsby-plugin-sanity-image'

import BlockContent from '../block-content'

import BasaltStories from '../../assets/svg/stories.svg'
import ScrollArrow from '../../assets/svg/arrow_scroll.svg'

const StoriesHero = ({ hero, _rawStories }) => {
  return(
    <>
      <section id="stories-hero">
        <div className="layer-top" data-sal="slide-up" data-sal-delay="100" data-sal-duration="850">
          <BlockContent className="block-bold small" blocks={hero._rawText || []}/>
          <ScrollArrow className="scroll-arrow"/>
        </div>
        <div className="layer-bottom">
          <div className="image-container">
            <Image {...hero.image} className="hero-image"/>
          </div>
        </div>
      </section>

      <section id="stories-title">
      <div className="layer-top" data-sal="slide-up" data-sal-delay="100" data-sal-duration="850">
        <BlockContent className="block-bold extra" blocks={_rawStories.storiesTitle || []}/>
        <BlockContent className="block-regular-big" blocks={_rawStories.storiesText || []}/>
      </div>
      <div className="layer-bottom" data-sal="fade" data-sal-delay="300" data-sal-duration="650">
        <BasaltStories className="basalt"/>
      </div>
      </section>
    </>
  )
}

export default StoriesHero
